/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout';
var marketingRouter = {
  path: '/marketing',
  component: Layout,
  redirect: '/PlatformCoupon/list',
  name: 'Marketing',
  meta: {
    title: '营销',
    icon: 'clipboard'
  },
  children: [{
    path: 'sign',
    component: function component() {
      return import('@/views/marketing/sign');
    },
    name: 'Sign',
    meta: {
      title: '签到',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return import('@/views/marketing/sign/config/index');
      },
      name: 'signConfig',
      hidden: true,
      meta: {
        title: '签到配置',
        icon: ''
      }
    }, {
      path: 'record',
      component: function component() {
        return import('@/views/marketing/sign/record/index');
      },
      name: 'signRecord',
      hidden: true,
      meta: {
        title: '签到记录',
        icon: ''
      }
    }]
  }, {
    path: 'specialArea',
    component: function component() {
      return import('@/views/marketing/specialArea/index');
    },
    name: 'SpecialArea',
    meta: {
      title: '特色专区',
      icon: ''
    },
    children: [{
      path: 'specialAreaList',
      component: function component() {
        return import('@/views/marketing/specialArea/specialAreaList/index');
      },
      name: 'specialAreaList',
      meta: {
        title: '特色专区列表',
        icon: ''
      }
    }, {
      path: 'specialAreaMenu',
      component: function component() {
        return import('@/views/marketing/specialArea/specialAreaMenu/index');
      },
      name: 'specialAreaMenu',
      meta: {
        title: '特色专区菜单',
        icon: ''
      }
    }, {
      path: 'zonePlate/:id?/:copy?',
      name: 'zonePlate',
      meta: {
        title: '添加专区',
        noCache: true,
        activeMenu: "/marketing/specialArea/specialAreaMenu"
      },
      component: function component() {
        return import('@/views/marketing/specialArea/specialAreaMenu/zonePlate');
      }
    }]
  }, {
    path: 'integral',
    component: function component() {
      return import('@/views/marketing/integral/index');
    },
    name: 'Integral',
    meta: {
      title: '积分',
      icon: ''
    },
    children: [{
      path: 'integralconfig',
      component: function component() {
        return import('@/views/marketing/integral/config/index');
      },
      name: 'integralConfig',
      meta: {
        title: '积分配置',
        icon: ''
      }
    }, {
      path: 'integrallog',
      component: function component() {
        return import('@/views/marketing/integral/integralLog/index');
      },
      name: 'integralLog',
      meta: {
        title: '积分日志',
        icon: ''
      }
    }]
  }, {
    path: 'seckill',
    component: function component() {
      return import('@/views/marketing/seckill/index');
    },
    name: 'Seckill',
    meta: {
      title: '秒杀',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return import('@/views/marketing/seckill/seckillConfig/index');
      },
      name: 'SeckillConfig',
      meta: {
        title: '秒杀配置',
        icon: ''
      }
    }, {
      path: 'list/:timeId?',
      component: function component() {
        return import('@/views/marketing/seckill/seckillList/index');
      },
      name: 'SeckillList',
      meta: {
        title: '秒杀商品',
        icon: ''
      }
    }, {
      path: 'seckillActivity',
      component: function component() {
        return import('@/views/marketing/seckill/seckillActivity/index');
      },
      name: 'SeckillActivity',
      meta: {
        title: '秒杀活动',
        icon: ''
      }
    }, {
      path: 'creatSeckill/:id?/:type?',
      component: function component() {
        return import('@/views/marketing/seckill/seckillActivity/creatSeckill');
      },
      name: 'CreatSeckill',
      meta: {
        title: '添加秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/seckillActivity"
      }
    }]
  }, {
    path: 'groupBuy',
    component: function component() {
      return import('@/views/marketing/groupBuy/index');
    },
    name: 'groupBuy',
    meta: {
      title: '拼团管理',
      icon: ''
    },
    hidden: true,
    children: [{
      path: 'groupGoods',
      component: function component() {
        return import('@/views/marketing/groupBuy/groupGoods/index');
      },
      name: 'groupGoods',
      meta: {
        title: '拼团商品',
        icon: ''
      }
    }, {
      path: 'creatGroup/:id?/:type?',
      component: function component() {
        return import('@/views/marketing/groupBuy/groupGoods/creatGroup');
      },
      name: 'creatGroup',
      meta: {
        title: '拼团商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/groupBuy/groupGoods"
      }
    }, {
      path: 'groupList',
      component: function component() {
        return import('@/views/marketing/groupBuy/groupList/index');
      },
      name: 'groupList',
      meta: {
        title: '拼团列表',
        icon: ''
      }
    }]
  }, {
    path: 'atmosphere',
    name: 'atmosphere',
    meta: {
      title: '活动氛围',
      noCache: true
    },
    component: function component() {
      return import('@/views/marketing/atmosphere/index');
    },
    children: [{
      path: 'list',
      name: "atmosphereList",
      meta: {
        title: '氛围列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/atmosphere/atmosphereList/list');
      }
    }, {
      path: 'add/:id?',
      name: "addAtmosphere",
      meta: {
        title: '添加活动氛围',
        noCache: true,
        activeMenu: "/marketing/atmosphere/list"
      },
      component: function component() {
        return import('@/views/marketing/atmosphere/atmosphereList/addAtmosphere');
      }
    }]
  }, {
    path: 'border',
    name: 'border',
    meta: {
      title: '活动边框',
      icon: ''
    },
    component: function component() {
      return import('@/views/marketing/border/index');
    },
    children: [{
      path: 'list',
      name: "borderList",
      meta: {
        title: '活动边框列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/atmosphere/atmosphereList/list');
      }
    }, {
      path: 'add/:id?',
      name: "addBorder",
      meta: {
        title: '添加活动边框',
        noCache: true,
        activeMenu: "/marketing/border/list"
      },
      component: function component() {
        return import('@/views/marketing/atmosphere/atmosphereList/addAtmosphere');
      }
    }]
  }, {
    path: 'broadcast',
    name: 'Broadcast',
    meta: {
      title: '直播',
      noCache: true
    },
    component: function component() {
      return import('@/views/marketing/broadcast/index');
    },
    children: [{
      path: 'list',
      name: 'BroadcastList',
      meta: {
        title: '直播间管理',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/broadcast/list/index');
      }
    }, {
      path: 'product',
      name: 'BroadcastProduct',
      meta: {
        title: '直播商品列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/broadcast/product/index');
      }
    }]
  }, {
    path: 'PlatformCoupon',
    name: 'PlatformCoupon',
    meta: {
      title: '平台优惠券',
      noCache: true
    },
    component: function component() {
      return import('@/views/marketing/platformCoupon/index');
    },
    children: [{
      path: 'list',
      name: 'PlatformCouponlist',
      meta: {
        title: '优惠劵列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/index');
      }
    }, {
      path: 'creatCoupon/:id?/:copy?',
      name: 'CreatCoupon',
      meta: {
        title: '添加优惠劵',
        noCache: true,
        activeMenu: "/marketing/PlatformCoupon/list"
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/creatCoupon');
      }
    }, {
      path: 'releaseRecord/:id?/:copy?',
      name: 'releaseRecord',
      meta: {
        title: '领取/发放记录',
        noCache: true,
        activeMenu: "/marketing/PlatformCoupon/list"
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/releaseRecord');
      }
    }, {
      path: 'couponRecord',
      name: 'couponRecord',
      meta: {
        title: '领取记录',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/record');
      }
    }, {
      path: 'couponCampaign',
      name: 'couponCampaign',
      meta: {
        title: '优惠券活动',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/campaign');
      }
    }, {
      path: 'batchissue',
      name: 'batchissue',
      meta: {
        title: '批量发券',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/batchIssue');
      }
    }, {
      path: 'campaignDetails/:id?/:copy?',
      name: 'campaignDetails',
      meta: {
        title: '优惠券活动详情',
        noCache: true,
        activeMenu: "/marketing/PlatformCoupon/couponCampaign"
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/campaignDetails');
      }
    }, {
      path: 'newGift',
      name: 'newGift',
      meta: {
        title: '用户有礼',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/platformCoupon/couponList/newGift');
      }
    }]
  }, {
    path: 'videoChannel',
    name: 'VideoChannel',
    meta: {
      title: '视频号',
      noCache: true
    },
    component: function component() {
      return import('@/views/videoChannel');
    },
    children: [{
      path: 'list',
      component: function component() {
        return import('@/views/videoChannel/videoList/index');
      },
      name: 'VideoChannelList',
      meta: {
        title: '商品列表',
        icon: '',
        noCache: true
      }
    }, {
      path: 'draftList',
      component: function component() {
        return import('@/views/videoChannel/draftList/index');
      },
      name: 'draftList',
      meta: {
        title: '草稿列表',
        icon: '',
        noCache: true
      }
    }, {
      path: 'apply',
      component: function component() {
        return import('@/views/videoChannel/apply/index');
      },
      name: 'videoApply',
      meta: {
        title: '申请接入',
        icon: '',
        noCache: true
      }
    }, {
      path: 'weChatcategory',
      component: function component() {
        return import('@/views/videoChannel/weChatcategoryAndBrand/index');
      },
      name: 'videoWeChatcategory',
      meta: {
        title: '微信商品类目',
        icon: '',
        noCache: true
      }
    }]
  }, {
    path: 'community',
    name: 'Community',
    meta: {
      title: '种草社区',
      noCache: true
    },
    component: function component() {
      return import('@/views/community');
    },
    children: [{
      path: 'classification',
      name: 'communityClassification',
      component: function component() {
        return import('@/views/community/classification/index');
      },
      meta: {
        title: '社区分类',
        icon: 'clipboard'
      }
    }, {
      path: 'topics',
      name: 'communityTopics',
      component: function component() {
        return import('@/views/community/topics/index');
      },
      meta: {
        title: '社区话题',
        icon: 'clipboard'
      }
    }, {
      path: 'content',
      name: 'communityContent',
      component: function component() {
        return import('@/views/community/content/index');
      },
      meta: {
        title: '社区内容',
        icon: 'clipboard'
      }
    }, {
      path: 'comments',
      name: 'communityComments',
      component: function component() {
        return import('@/views/community/comments/index');
      },
      meta: {
        title: '社区评论',
        icon: 'clipboard'
      }
    }, {
      path: 'config',
      name: 'communityConfig',
      component: function component() {
        return import('@/views/community/config/index');
      },
      meta: {
        title: '社区配置',
        icon: 'clipboard'
      }
    }]
  }, {
    path: 'content',
    name: 'Content',
    meta: {
      title: '公告内容',
      noCache: true
    },
    component: function component() {
      return import('@/views/content');
    },
    children: [{
      path: 'articleManager',
      name: 'articleManager',
      component: function component() {
        return import('@/views/content/article/list');
      },
      meta: {
        title: '文章管理',
        icon: 'clipboard'
      }
    }, {
      path: 'articleCreat/:id?',
      name: 'articleCreat',
      component: function component() {
        return import('@/views/content/article/edit');
      },
      meta: {
        title: '添加文章',
        noCache: true,
        activeMenu: "/content/articleManager"
      }
    }, {
      path: 'classifManager',
      name: 'classifManager',
      component: function component() {
        return import('@/views/content/articleclass/list');
      },
      meta: {
        title: '文章分类',
        icon: 'clipboard'
      }
    }]
  }, {
    path: 'homePopup',
    name: 'homePopup',
    meta: {
      title: '开屏弹窗',
      noCache: true
    },
    component: function component() {
      return import('@/views/marketing/homePopup');
    }
  }, {
    path: 'operation',
    name: 'operation',
    meta: {
      title: '运营活动',
      icon: ''
    },
    component: function component() {
      return import('@/views/marketing/operation/index');
    },
    children: [{
      path: 'list',
      name: "list",
      meta: {
        title: '运营活动列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/marketing/operation/operationList/list');
      }
    }, {
      path: 'add/:id?/:type?',
      name: "operationAdd",
      meta: {
        title: '添加运营活动',
        noCache: true,
        activeMenu: "/marketing/operationList/list"
      },
      component: function component() {
        return import('@/views/marketing/operation/operationList/operationAdd');
      }
    }]
  }, {
    path: 'luckyDraw',
    name: 'luckyDraw',
    meta: {
      title: '抽奖活动',
      icon: ''
    },
    component: function component() {
      return import('@/views/marketing/luckyDraw/index');
    },
    children: [{
      path: 'allocationDraw',
      name: "allocationDraw",
      meta: {
        title: '配置抽奖',
        icon: ''
      },
      component: function component() {
        return import('@/views/marketing/luckyDraw/allocationDraw/index');
      }
    }, {
      path: 'add/:id?',
      name: "addList",
      meta: {
        title: '添加配置抽奖',
        noCache: true,
        activeMenu: "/marketing/luckyDraw/allocationDraw"
      },
      component: function component() {
        return import('@/views/marketing/luckyDraw/allocationDraw/add');
      }
    }, {
      path: 'records',
      name: "records",
      meta: {
        title: '抽奖记录',
        icon: ''
      },
      component: function component() {
        return import('@/views/marketing/luckyDraw/records/index');
      }
    }]
  }]
};
export default marketingRouter;